import axios from 'axios';
import cx from 'classnames';
import { Api } from 'enums/api';
// eslint-disable-next-line
import React, { memo, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { companyId$ } from 'selectors/auth';
import { catchedErrorNotification } from 'utils/error';
import { findGetParameter } from 'utils/findGetParameter';

import style from './background.module.scss';

interface IProps {
  children: JSX.Element;
  setCompanyId: (id: number) => void;
}

const BackgroundInternal = ({ children, setCompanyId }: IProps) => {
  const history = useHistory();
  const [isCustomBg, setIsCustomBg] = useState(false);
  const [background, setBackground] = useState('');
  const [isLoading, setLoading] = useState(false);
  const childMemo = useMemo(() => children, [children]);
  const company_id = useSelector(companyId$);

  const fetchAffiliateBg = async (affiliate_code: string) => {
    setLoading(true);
    await axios
      .post(Api.GetAffiliateBg, { affiliate_code })
      .then(res => {
        if (res.data.result?.company_background) {
          setBackground(res.data.result?.company_background);
          setCompanyId(res.data.result?.company_id);
        } else {
          setCompanyId(res.data.result?.company_id);
          setIsCustomBg(false);
        }
      })
      .catch(err => {
        catchedErrorNotification(err);
        setIsCustomBg(false);
      });

    setLoading(false);
  };

  const fetchBGByCompanyId = async () => {
    setLoading(true);
    await axios
      .post(Api.GetSingUpBgByCompanyId, { company_id })
      .then(res => {
        if (res.data.result?.company_background) {
          setBackground(res.data.result?.background);
        } else {
          setIsCustomBg(false);
        }
      })
      .catch(err => {
        catchedErrorNotification(err);
        setIsCustomBg(false);
      });

    setLoading(false);
  };

  useEffect(() => {
    const affiliateParam = findGetParameter('afmc', history);
    if (affiliateParam && !isLoading) {
      setIsCustomBg(true);
      try {
        fetchAffiliateBg(affiliateParam);
      } catch (error) { }
    } else {
      setIsCustomBg(false);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (company_id) {
      fetchBGByCompanyId();
    }
    // eslint-disable-next-line
  }, [company_id]);

  const RootCX = cx(style.root, { [style.custom]: isCustomBg, [style.loading]: isLoading });

  return (
    <div
      className={RootCX}
      style={background ? { background: `url(${background}) no-repeat center center fixed`, backgroundSize: 'cover' } : {}}
    >
      {childMemo}
    </div>
  );
};

export const Background = memo(BackgroundInternal);
