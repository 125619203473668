import style from './microPromotions.module.scss';

import StatisticPanel from 'containers/statisticPanel/statisticPanel';
import * as CurrentSubscriptionCreators from 'creators/currentSubscription';
import * as StatsCreators from 'creators/stats';
import State from 'interfaces/state/State';
import { IStatTrack } from 'interfaces/state/StatsState';
// eslint-disable-next-line
import React, { memo, useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import * as StatsSelectors from 'selectors/stats';
import { CustomSvgIcon } from 'utils/CustomSvgIcon';
import { svgPathAttr } from 'utils/SVGPath';
import { S3URL } from 'utils/s3Url';
import { isAuth$, userEmail$, userType$ } from 'selectors/auth';
import { fetchSubscribedPromotion, subscribeToPromotion } from 'creators/promotions';
import { catchedErrorNotification } from 'utils/error';
import { message } from 'utils/message';
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import { Link } from "react-router-dom";

interface IProps { }

interface IStateProps {
  activeJobsCount: number;
  active_jobs_all: number;
  tracks: IStatTrack[];
}

interface IDispatchProps {
  getStats: () => void;
  fetchCurrentSubscription: () => void;
}

const MicroPromotionsPage = ({
  activeJobsCount,
  active_jobs_all,
  tracks,
  getStats,
  fetchCurrentSubscription,
}: IProps & IStateProps & IDispatchProps) => {
  const [email, setEmail] = useState('');
  const [uType, setUType] = useState('');
  const [thumbnailLoading, setThumbnailLoading] = useState(true);

  const isAuth = useSelector(isAuth$);
  const userEmail = useSelector(userEmail$);
  const isSubscribed = useSelector((state: State) => state.microPromotion.isSubscribed);
  const userType = useSelector(userType$);

  const dispatch = useDispatch();
  const [isVideoPopupOpen, setIsVideoPopupOpen] = useState(false);

  const toggleVideoPopup = () => {
    setIsVideoPopupOpen((prevState) => !prevState);
  };

  useEffect(() => {
    if (isAuth) {
      getStats();
    }
  }, [isAuth]);

  useEffect(() => {
    if (isAuth) fetchCurrentSubscription();
  }, [isAuth, fetchCurrentSubscription]);


  useEffect(() => {
    if (userEmail) setEmail(userEmail!);
    if (userType) setUType(userType!);
  }, [userEmail, userType]);

  useEffect(() => {
    if (userEmail) {
      dispatch(fetchSubscribedPromotion(userEmail, 'micro_promotions'));
    }
  }, [userEmail, dispatch]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!email) {
      catchedErrorNotification(new Error(message.emptyEmail));
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      catchedErrorNotification(new Error(message.invalidEmail));
      return;
    }

    dispatch(subscribeToPromotion(email, 'micro_promotions', message.subscribeToPromotion));
  };

  const videoUrl = S3URL[uType as keyof typeof S3URL] || '';

  return (
    <section className='info-c'>
      <div className="musician-content d-flex flex-column align-center show-animation">
        <div className="info-container-mus">
          <StatisticPanel getStats={getStats} tracks={tracks} allJobsCount={active_jobs_all} activeCount={activeJobsCount} />
        </div>
      </div>
      {isSubscribed && (
        <div className={style.optedInMsg}>THANK YOU! You are on our Beta list and we will notify you soon as we go live!</div>
      )}
      <div className={`${style.root} ${isSubscribed ? style.rootAlert : ""}`}>
        <div className={style.container}>
          <div className={style.promotionLabelsAndFormWrapper}>
            <div className={style.promotionLabelsBox}>
              <p className={style.labelPara}>COMING SOON</p>
              <h1 className={style.labelHead}>Earn Money Promoting</h1>
              <p className={style.labelDesc}>Companies and Creators all over the world pay you to help promote their creative projects.</p>
              {!isSubscribed && (
                <div className={style.promotionFormBox}>
                  <form className={style.promotionForm} onSubmit={handleSubmit}>
                    <button type="submit" className={style.submitPromotionMail}>
                      OPT IN <CustomSvgIcon width={svgPathAttr.arrowRight.width} height={svgPathAttr.arrowRight.height} viewBox={svgPathAttr.arrowRight.viewBox} svgFill={svgPathAttr.arrowRight.svgFill} pathFill={svgPathAttr.arrowRight.pathFill} path={svgPathAttr.arrowRight.d} />
                    </button>
                  </form>
                </div>
              )}
              {isSubscribed && (
                <div className={style.mt32}></div>
              )}
              <div className={style.infoBox}>
                <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 24 24" fill="none" stroke="#3182ce" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                  <circle cx="12" cy="12" r="10"></circle>
                  <line x1="12" y1="16" x2="12" y2="12"></line>
                  <line x1="12" y1="8" x2="12" y2="8"></line>
                </svg>
                <p className={style.infoPara}>
                  While we boot up the new tech to support <br /><strong>Micro-Promoters</strong>, you can start earning today by inviting music makers to the SmashHaus team via our affiliate program
                  <Link to="/affiliate" className={style.infoLink}>HERE</Link>.
                </p>
              </div>
            </div>
          </div>
          <div className={style.videoThumbnailWrapper}>
            {thumbnailLoading && (
              <div className={style.skeletonLoader} />
            )}
            <img
              src={S3URL.microPromotionThumbnail}
              onLoad={() => setThumbnailLoading(false)}
              className={`${style.thumbnail} ${thumbnailLoading ? style.hidden : style.visible}`}
              alt="NETWRK Video Thumbnail"
            />
            {!thumbnailLoading && (
              <div onClick={toggleVideoPopup} className={style.playBtnOverlay}>
                <PlayCircleOutlineIcon className={style.playIcon} />
              </div>
            )}
          </div>
        </div>
      </div>

      {isVideoPopupOpen && (
        <div className={style.videoPopupWrapper}>
          <div className={style.overlay} onClick={toggleVideoPopup}></div>
          <div className={style.playerWrapper}>
            <button
              className={style.closeButton}
              onClick={(e) => {
                e.stopPropagation();
                toggleVideoPopup();
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <line x1="18" y1="6" x2="6" y2="18" />
                <line x1="6" y1="6" x2="18" y2="18" />
              </svg>
            </button>
            <video
              className={style.videoPlayer}
              controls
              autoPlay
              src={videoUrl}
            />
          </div>
        </div>
      )}
    </section>
  );
};

const mapStateToProps = (state: State) => {
  return {
    active_jobs_all: StatsSelectors.active_jobs_all$(state),
    activeJobsCount: StatsSelectors.active_jobs$(state),
    tracks: StatsSelectors.statisticTracks$(state),
  };
};

const mergeProps = (stateProps: IStateProps, dispatchProps: IDispatchProps, props: IProps) => {
  return {
    ...stateProps,
    getStats: dispatchProps.getStats,
    fetchCurrentSubscription: dispatchProps.fetchCurrentSubscription,
    ...props,
  };
};

export default connect(
  mapStateToProps,
  {
    getStats: StatsCreators.getStats,
    fetchCurrentSubscription: CurrentSubscriptionCreators.fetchCurrentSubscription,
  },
  mergeProps
)(memo(MicroPromotionsPage));
