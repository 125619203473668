import React from 'react';

import cancel_popup from '../../assets/img/cancel_popup.png';
import Haus_Icon_cleanV3 from '../../assets/img/Haus_Icon_cleanV3.png';
import music_popup_four from '../../assets/img/music_popup_four.png';
import music_popup_one from '../../assets/img/music_popup_one.png';
import music_popup_three from '../../assets/img/music_popup_three.png';
import music_popup_two from '../../assets/img/music_popup_two.png';
import styles from './promotionPopup.module.scss';

interface PromotionPopupProps {
  onClose: () => void;
}

const images = [music_popup_one, music_popup_two, music_popup_three, music_popup_four];

const PopupData = [
  {
    id: 1,
    mainHeading: 'Unlock Your',
    subHeading: 'Potential:',
    src: '',
    mainText:
      'Did you know Premium subscribers appear higher in the list on music submissions, massively increasing your exposure to clients and jobs?',
    subText:
      'Upgrade now to get noticed and put your music in front of decision-makers. Elevate your profile and let your talent shine with a Premium membership.',
  },
  {
    id: 2,
    mainHeading: 'Maximize',
    subHeading: 'Your Reach :',
    src: music_popup_two,
    mainText: 'As a Premium member, you can create your own playlists to share with clients.',
    subText:
      'This feature allows you to curate your best tracks and present them professionally, making it easier for clients to find and appreciate your work. Showcase your talent and stand out from the crowd with personalized playlists.',
  },
  {
    id: 3,
    mainHeading: 'Personalized',
    subHeading: 'Support :',
    src: music_popup_two,
    mainText: 'Get Premium and we provide a dedicated staff member to help you get the best opportunity to convert in-system.',
    subText:
      "We even work to pitch your music for you, increasing your chances of landing jobs. With our team by your side, you'll receive guidance and support tailored to your unique needs.",
  },
  {
    id: 2,
    mainHeading: 'Unlimited',
    subHeading: 'Opportunities :',
    src: music_popup_two,
    mainText: 'Tired of the 2-track limit? ',
    subText:
      'Pro and Premium members can upload more music and apply to more jobs, giving you more chances to succeed. Expand your portfolio and increase your visibility to potential clients by upgrading your membership.',
  },
  {
    id: 2,
    mainHeading: 'Exclusive',
    subHeading: 'Access :',
    src: music_popup_two,
    mainText:
      'Premium members enjoy full access to all the jobs on our board. Don’t miss out on exclusive opportunities that can take your career to the next level',
    subText: 'Upgrade today and open the door to a world of new possibilities and projects.',
  },
  {
    id: 2,
    mainHeading: 'Enhanced',
    subHeading: 'Storage :',
    src: music_popup_two,
    mainText: 'Pro and Premium members get increased storage to upload and showcase more of their music.',
    subText:
      'Never worry about running out of space again, and ensure your complete portfolio is available for clients to explore. Keep your best work at your fingertips with enhanced storage options.',
  },
  {
    id: 2,
    mainHeading: 'Priority',
    subHeading: 'Support :',
    src: music_popup_two,
    mainText:
      'Need help navigating the platform? Premium members receive personalized support from our team, ensuring you make the most of your membership',
    subText: 'Our support staff is here to answer your questions and provide the assistance you need to succeed.',
  },
  {
    id: 2,
    mainHeading: 'Boost Your',
    subHeading: 'Exposure :',
    src: music_popup_two,
    mainText:
      'As a Premium member, our team actively pitches your music to potential clients, increasing your chances of landing more jobs.',
    subText:
      'Upgrade and let us work for you, leveraging our connections and expertise to promote your music. Enjoy the peace of mind that comes with knowing we are on your side.',
  },
];

const PromotionPopup: React.FC<PromotionPopupProps> = ({ onClose }) => {
  const randomIndex = Math.floor(Math.random() * (PopupData.length - 1));
  console.log(PopupData, "PopupData", randomIndex, '==randomIndex');

  const data = { ...PopupData[randomIndex], src: images[Math.floor(Math.random() * (images.length - 1))] };
  return (
    <div className={styles.promotion_popup}>
      <div className={styles.background}></div>
      <div className={styles.mainContent}>
        <div className={styles.main_image}>
          <img src={data.src} alt="Music Popup Image" />
        </div>
        <div className={styles.second_section}>
          <p className={styles.cancel}>
            <img src={cancel_popup} onClick={onClose} alt="Cancel Popup" />
          </p>
          <div className={styles.icon}>
            <img src={Haus_Icon_cleanV3} alt="" width={80} height={65} />
          </div>
          <div className={styles.headingContent}>
            <h1 className={styles.main_heading}>{data.mainHeading}</h1>
            <h1 className={styles.sub_heading}>{data.subHeading}</h1>
          </div>
          <div className={styles.textContent}>
            <p className={styles.mainText}>{data.mainText}</p>
            <p className={styles.subText}>{data.subText}</p>
          </div>

          <button className={styles.button} onClick={() => (window.location.href = '/pricing')}>
            Upgrade
          </button>
        </div>
      </div>
    </div>
  );
};

export default PromotionPopup;
