import { combineReducers } from 'redux';

import account from './account';
import admin from './admin';
import affiliate from './affiliate';
import applications from './application';
import auth from './auth';
import avatar from './avatar';
import billing from './billing';
import companies from './companies';
import companyJobs from './companyJobs';
import confirm from './confirm';
import createApplication from './createApplication';
import currentSubscription from './currentSubscription';
import downloadFile from './downloadFile';
import downloadTrack from './downloadTrack';
import help from './help';
import invite from './invite';
import managerEventListener from './managerEventListener';
import message from './message';
import musicianJobs from './musicianJob';
import musicianPlayer from './musicianPlayer';
import newJobAvatar from './newJobAvatar';
import newTracks from './newTracks';
import paymentMethod from './paymentMethod';
import playlist from './playlist';
import rating from './rating';
import receivePass from './receivePass';
import registerFromInvite from './registerFromInvite';
import shareablePlaylistInfo from './shareablePlaylistInfo';
import shareablePlaylistLinks from './shareablePlaylistLinks';
import sharedPlayer from './sharedPlayer';
import sharedPlaylist from './sharedPlaylist';
import sharedPlaylistTracks from './sharedPlaylistTracks';
import signUp from './signUp';
import siteEvents from './siteEvents';
import song from './song';
import statistics from './statistics';
import stats from './stats';
import subscriptionLimit from './subscriptionLimit';
import subscriptionList from './subscriptionList';
import successPayment from './successPayment';
import tab from './tab';
import team from './team';
import teamInvite from './teamInvite';
import tempEditCompanyJob from './tempEditCompanyJob';
import trackInfo from './trackInfo';
import trackInformation from './trackInformation';
import unsubscribe from './unsubscribe';
import updateApprove from './updateApprove';
import uploadTrack from './uploadTrack';
import users from './users';
import promotion from './promotion';

const rootReducer = combineReducers({
  account,
  auth,
  tab,
  signUp,
  companyJobs,
  help,
  message,
  musicianJobs,
  uploadTrack,
  playlist,
  confirm,
  newTracks,
  createApplication,
  stats,
  users,
  rating,
  song,
  receivePass,
  managerEventListener,
  trackInfo,
  musicianPlayer,
  avatar,
  newJobAvatar,
  tempEditCompanyJob,
  admin,
  siteEvents,
  companies,
  invite,
  sharedPlaylist,
  registerFromInvite,
  downloadTrack,
  sharedPlaylistTracks,
  shareablePlaylistInfo,
  shareablePlaylistLinks,
  trackInformation,
  sharedPlayer,
  subscriptionList,
  successPayment,
  unsubscribe,
  updateApprove,
  currentSubscription,
  affiliate,
  team,
  teamInvite,
  applications,
  subscriptionLimit,
  downloadFile,
  paymentMethod,
  billing,
  statistics,
  microPromotion: promotion,
});

export default rootReducer;
