import 'sweetalert2/src/sweetalert2.scss';

import axios from 'axios';
import { successNotification } from 'components/successNotification/successNotification';
import { Api } from 'enums/api';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { catchedErrorNotification } from 'utils/error';
import { message } from 'utils/message';

import * as SubscribePromotion from '../actions/promotion';

export const subscribeToPromotion = (email: string, promotion_type: string, successMsg: string) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    if (!email) {
      catchedErrorNotification(new Error(message.emptyEmail));
      return;
    }

    dispatch(SubscribePromotion.setSubscribePromotionLoading());

    try {
      await axios.post(Api.SubscribePromotion, {
        email,
        promotion_type
      });

      dispatch(SubscribePromotion.setSubscribePromotionSuccess());
      successNotification(successMsg);
    } catch (error) {
      dispatch(SubscribePromotion.setSubscribePromotionError());
      catchedErrorNotification(error);
    }
  };
};

export const fetchSubscribedPromotion = (email: string, promotion_type: string) => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
      dispatch(SubscribePromotion.setFetchSubscribePromotionLoading());
  
      try {
        const response = await axios.post(Api.GetSubscribedPromotion, {
          email,
          promotion_type
        });
        const isSubscribed = response.data.result !== null;
        dispatch(SubscribePromotion.setFetchSubscribePromotionSuccess(isSubscribed));
      } catch (error) {
        dispatch(SubscribePromotion.setFetchSubscribePromotionError());
        catchedErrorNotification(error);
      }
    };
};
