import './style.scss';

// import ContactMailRoundedIcon from '@material-ui/icons/ContactMailRounded';
import EqualizerRoundedIcon from '@material-ui/icons/EqualizerRounded';
import HeadsetIcon from '@material-ui/icons/Headset';
import HowToRegRoundedIcon from '@material-ui/icons/HowToRegRounded';
import ListIcon from '@material-ui/icons/List';
import PeopleAltSharpIcon from '@material-ui/icons/PeopleAltSharp';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import PlaylistPlayRoundedIcon from '@material-ui/icons/PlaylistPlayRounded';
// import PlaylistPlayIcon from '@material-ui/icons/PlaylistPlay';
import QueueMusicIcon from '@material-ui/icons/QueueMusic';
// import RecentActorsRoundedIcon from '@material-ui/icons/RecentActorsRounded';
import WorkIcon from '@material-ui/icons/Work';
import { CustomSvgIcon } from 'utils/CustomSvgIcon';
import { svgPathAttr } from 'utils/SVGPath';
// import { ReactComponent as PricingIcon } from 'assets/img/pricing.svg';
import Tab from 'containers/tab/tab';
// eslint-disable-next-line
import React from 'react';
import { useSelector } from 'react-redux';
import { userType$ } from 'selectors/auth';
import masteringIcon from 'assets/img/mastering-icon.png';

import styles from './switchTabs.module.scss';

export interface ITab {
  index: number;
  name: string;
  classStyle: string;
  title: string;
  icon?: JSX.Element;
  route: string;
  isNew?: boolean;
  hide?: boolean;
}
const managerTabs: ITab[] = [
  {
    index: 0,
    name: 'First Tab',
    classStyle: 'tab company-tab',
    title: 'JOB BOARD',
    icon: <PlaylistAddIcon className={styles.icon} />,
    route: '/manager/job/list',
  },
  {
    index: 1,
    name: 'Second Tab',
    classStyle: 'tab company-tab',
    title: 'MUSIC SUBMISSIONS',
    icon: <HeadsetIcon className={styles.icon} />,
    route: '/manager/jobs/active',
  },
  {
    index: 2,
    name: 'Third Tab',
    classStyle: 'tab company-tab',
    title: 'COMPANIES',
    icon: <ListIcon className={styles.icon} />,
    route: '/manager/company/list',
  },
  {
    index: 8,
    name: 'MASTERING CHANNEL',
    classStyle: 'tab musician-first-tab',
    title: 'MASTERING CHANNEL',
    icon: <img className={styles.icon} src={masteringIcon} alt="mastering channel icon" loading="lazy" />,
    route: '/mastering-channel',
  },
  {
    index: 3,
    name: 'Fourth Tab',
    classStyle: 'tab company-tab',
    title: 'COMPANY INVITATIONS',
    icon: <PersonAddIcon className={styles.icon} />,
    route: '/manager/invite/list',
  },
  // {
  //   index: 4,
  //   name: 'Fifth Tab',
  //   classStyle: 'tab company-tab',
  //   title: 'AFFILIATE',
  //   icon: <ContactMailRoundedIcon className={styles.icon} />,
  //   route: '/affiliate',
  // },
  {
    index: 5,
    name: 'Sixth Tab',
    classStyle: 'tab company-tab',
    title: 'TEAM',
    icon: <HowToRegRoundedIcon className={styles.icon} />,
    route: '/team',
  },
  {
    index: 6,
    name: 'MICRO PROMOTIONS',
    classStyle: 'tab musician-first-tab',
    title: 'MICRO PROMOTIONS',
    icon: <CustomSvgIcon width={svgPathAttr.hornFill.width} height={svgPathAttr.hornFill.height} viewBox={svgPathAttr.hornFill.viewBox} svgFill={svgPathAttr.hornFill.svgFill} pathFill={svgPathAttr.hornFill.pathFill} path={svgPathAttr.hornFill.d} className={styles.icon} />,
    route: '/micro-promotions',
    isNew: true,
  },
  {
    index: 7,
    name: 'NETWRK',
    classStyle: 'tab musician-first-tab',
    title: 'NETWRK',
    icon: <CustomSvgIcon width={svgPathAttr.netwrk.width} height={svgPathAttr.netwrk.height} viewBox={svgPathAttr.netwrk.viewBox} svgFill={svgPathAttr.netwrk.svgFill} pathFill={svgPathAttr.netwrk.pathFill} path={svgPathAttr.netwrk.d} className={styles.icon} />,
    route: '/announce-netwrk',
    isNew: true,
  }
];

const adminTabs: ITab[] = [
  {
    index: 0,
    name: 'Zero Tab',
    classStyle: 'tab company-tab',
    title: 'STATISTICS',
    icon: <EqualizerRoundedIcon className={styles.icon} />,
    route: '/sh_admin/statistics',
  },
  {
    index: 1,
    name: 'First Tab',
    classStyle: 'tab company-tab',
    title: 'MUSIC SUBMISSIONS',
    icon: <HeadsetIcon className={styles.icon} />,
    route: '/sh_admin/jobs/active',
  },
  {
    index: 2,
    name: 'Second Tab',
    classStyle: 'tab musician-first-tab',
    title: 'JOB BOARD',
    icon: <PlaylistAddIcon className={styles.icon} />,
    route: '/sh_admin/job/list',
  },
  {
    index: 3,
    name: 'Third Tab',
    classStyle: 'tab company-tab',
    title: 'ADMIN PANEL',
    icon: <PeopleAltSharpIcon className={styles.icon} />,
    route: '/sh_admin/admin',
  },
  {
    index: 4,
    name: 'Fourth Tab',
    classStyle: 'tab company-tab',
    title: 'TEAM',
    icon: <HowToRegRoundedIcon className={styles.icon} />,
    route: '/team',
  },
  {
    index: 5,
    name: 'Fifth Tab',
    classStyle: 'tab company-tab',
    title: 'COMPANIES',
    icon: <ListIcon className={styles.icon} />,
    route: '/sh_admin/company/list',
  },
];

const creatorsTabs: ITab[] = [
  {
    index: 0,
    name: 'DASHBOARD',
    classStyle: 'tab musician-first-tab',
    title: 'DASHBOARD',
    icon: <EqualizerRoundedIcon className={styles.icon} />,
    hide: false, //true
    route: '/creator/statistic',
  },
  // {
  //   index: 1,
  //   name: 'Second Tab',
  //   classStyle: 'tab musician-second-tab',
  //   title: 'JOB BOARD',
  //   icon: <WorkIcon className={styles.icon} />,
  //   route: '/creator/job/list',
  // },
  {
    index: 1,
    name: 'JOB BOARD',
    classStyle: 'tab musician-first-tab',
    title: 'JOB BOARD',
    icon: <WorkIcon className={styles.icon} />,
    route: '/creator/job/list',
    isNew: false,
  },
  {
    index: 2,
    name: 'TRACK CATALOG',
    classStyle: 'tab musician-first-tab',
    title: 'TRACK CATALOG',
    icon: <QueueMusicIcon className={styles.icon} />,
    route: '/creator/track/list',
  },
  {
    index: 9,
    name: 'MASTERING CHANNEL',
    classStyle: 'tab musician-first-tab',
    title: 'MASTERING CHANNEL',
    icon: <img className={styles.icon} src={masteringIcon} alt="mastering channel icon" loading="lazy" />,
    route: '/mastering-channel',
  },
  // {
  //   index: 3,
  //   name: 'AFFILIATE',
  //   classStyle: 'tab musician-first-tab',
  //   title: 'AFFILIATE',
  //   icon: <ContactMailRoundedIcon className={styles.icon} />,
  //   route: '/affiliate',
  // },
  {
    index: 4,
    name: 'TEAM',
    classStyle: 'tab musician-first-tab',
    title: 'TEAM',
    icon: <HowToRegRoundedIcon className={styles.icon} />,
    route: '/team',
  },
  // {
  //   index: 5,
  //   name: 'PRICING',
  //   classStyle: 'tab musician-first-tab',
  //   title: 'PRICING',
  //   icon: <PricingIcon className={styles.icon} />,
  //   route: '/pricing',
  // },
  {
    index: 6,
    name: 'PLAYLIST',
    classStyle: 'tab musician-first-tab',
    title: 'PLAYLIST',
    icon: <PlaylistPlayRoundedIcon className={styles.icon} />,
    route: '/creator/playlist',
    isNew: false,
  },
  {
    index: 7,
    name: 'MICRO PROMOTIONS',
    classStyle: 'tab musician-first-tab',
    title: 'MICRO PROMOTIONS',
    icon: <CustomSvgIcon width={svgPathAttr.hornFill.width} height={svgPathAttr.hornFill.height} viewBox={svgPathAttr.hornFill.viewBox} svgFill={svgPathAttr.hornFill.svgFill} pathFill={svgPathAttr.hornFill.pathFill} path={svgPathAttr.hornFill.d} className={styles.icon} />,
    route: '/micro-promotions',
    isNew: true,
  },
  {
    index: 8,
    name: 'NETWRK',
    classStyle: 'tab musician-first-tab',
    title: 'NETWRK',
    icon: <CustomSvgIcon width={svgPathAttr.netwrk.width} height={svgPathAttr.netwrk.height} viewBox={svgPathAttr.netwrk.viewBox} svgFill={svgPathAttr.netwrk.svgFill} pathFill={svgPathAttr.netwrk.pathFill} path={svgPathAttr.netwrk.d} className={styles.icon} />,
    route: '/announce-netwrk',
    isNew: true,
  }
];

const tab = {
  creator: creatorsTabs.map(tab => <Tab key={tab.index} tab={tab} />),
  manager: managerTabs.map(tab => <Tab key={tab.index} tab={tab} />),
  admin: adminTabs.map(tab => <Tab key={tab.index} tab={tab} />),
};

export const SwitchTabs = () => {
  const userType = useSelector(userType$);
  if (userType) return tab[userType];
};
