import style from './masteringChannel.module.scss';
import StatisticPanel from 'containers/statisticPanel/statisticPanel';
import * as CurrentSubscriptionCreators from 'creators/currentSubscription';
import * as StatsCreators from 'creators/stats';
import { IStatTrack } from 'interfaces/state/StatsState';
import State from 'interfaces/state/State';
import React, { memo, useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import * as StatsSelectors from 'selectors/stats';
import { isAuth$ } from 'selectors/auth';

interface IProps { }

interface IStateProps {
  activeJobsCount: number;
  active_jobs_all: number;
  tracks: IStatTrack[];
}

interface IDispatchProps {
  getStats: () => void;
  fetchCurrentSubscription: () => void;
}

const MasteringChannelPage = ({
  activeJobsCount,
  active_jobs_all,
  tracks,
  getStats,
  fetchCurrentSubscription,
}: IProps & IStateProps & IDispatchProps) => {

  const isAuth = useSelector(isAuth$);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (isAuth) {
      getStats();
      fetchCurrentSubscription();
    }
  }, [isAuth, getStats, fetchCurrentSubscription]);

  return (
    <section className={style.masteringChannel}>
      <div className={`${style.musicianContent} d-flex flex-column align-center show-animation`}>
        <div className={style.infoContainerMus}>
          <StatisticPanel getStats={getStats} tracks={tracks} allJobsCount={active_jobs_all} activeCount={activeJobsCount} />
        </div>
      </div>

      <div className={`${style.root}`}>
        <div className={style.iframeWrapper}>
          {isLoading && <div className={style.skeletonLoader}></div>}
          <iframe
            title="Embedded Content"
            className={`${style.iframeEmbed} ${isLoading ? style.hidden : ""}`}
            src="https://partners.masterchannel.ai/partners/smashhaus/embed"
            data-loading="eager"
            onLoad={() => setIsLoading(false)}
            allowTransparency={true}
          ></iframe>
        </div>
        <div className={style.infoBox}>
          <p className={style.infoText}>
            Learn more about mastering with <strong>SmashHaus Master Channel</strong>. <br /> Explore the full details
            <a href="https://smashhaus.com/master-channel" className={style.infoLink} target="_blank" rel="noopener noreferrer">
              MORE INFO
            </a>
          </p>
        </div>
      </div>
    </section>
  );
};

const mapStateToProps = (state: State): IStateProps => ({
  active_jobs_all: StatsSelectors.active_jobs_all$(state),
  activeJobsCount: StatsSelectors.active_jobs$(state),
  tracks: StatsSelectors.statisticTracks$(state),
});

export default connect(mapStateToProps, {
  getStats: StatsCreators.getStats,
  fetchCurrentSubscription: CurrentSubscriptionCreators.fetchCurrentSubscription,
})(memo(MasteringChannelPage));
