import { Action } from 'redux';

import * as Const from '../constants';

export const setSubscribePromotionInit = (): Action => {
  return {
    type: Const.SUBSCRIBE_PROMOTION_INIT,
  };
};

export const setSubscribePromotionSuccess = (): Action => {
  return {
    type: Const.SUBSCRIBE_PROMOTION_SUCCESS,
  };
};

export const setSubscribePromotionError = (): Action => {
  return {
    type: Const.SUBSCRIBE_PROMOTION_ERROR,
  };
};

export const setSubscribePromotionLoading = (): Action => {
  return {
    type: Const.SUBSCRIBE_PROMOTION_PENDING,
  };
};

export const setFetchSubscribePromotionInit = (): Action => {
  return {
    type: Const.FETCH_SUBSCRIBE_PROMOTION_INIT,
  };
};

export const setFetchSubscribePromotionSuccess = (isSubscribed: boolean) => {
  return {
    type: Const.FETCH_SUBSCRIBE_PROMOTION_SUCCESS,
    payload: { isSubscribed },
  };
};

export const setFetchSubscribePromotionError = (): Action => {
  return {
    type: Const.FETCH_SUBSCRIBE_PROMOTION_ERROR,
  };
};

export const setFetchSubscribePromotionLoading = (): Action => {
  return {
    type: Const.FETCH_SUBSCRIBE_PROMOTION_PENDING,
  };
};
