import * as Const from '../constants';
import MicroPromotionState from '../interfaces/state/MicroPromotionState';
import { AnyAction } from 'redux';

const initialState: MicroPromotionState = {
    id: 0,
    email: '',
    promotionType: 'micro_promotions',
    isSubscribed: false,
};      

const updateState = (state: MicroPromotionState, updates: Partial<MicroPromotionState>): MicroPromotionState => ({
  ...state,
  ...updates,
});

export default function promotion(state = initialState, action: AnyAction): MicroPromotionState {
    switch (action.type) {
      case Const.SUBSCRIBE_PROMOTION_INIT:
        return updateState(state, { isSubscribed: false });
      case Const.SUBSCRIBE_PROMOTION_SUCCESS:
        return updateState(state, { isSubscribed: true });
      case Const.SUBSCRIBE_PROMOTION_PENDING:
        return updateState(state, {});
      case Const.SUBSCRIBE_PROMOTION_ERROR:
        return updateState(state, { isSubscribed: false });
  
      case Const.FETCH_SUBSCRIBE_PROMOTION_INIT:
        return updateState(state, { isSubscribed: false });
      case Const.FETCH_SUBSCRIBE_PROMOTION_SUCCESS:
        return updateState(state, { ...action.payload });
      case Const.FETCH_SUBSCRIBE_PROMOTION_PENDING:
        return updateState(state, {});
      case Const.FETCH_SUBSCRIBE_PROMOTION_ERROR:
        return updateState(state, { isSubscribed: false });
  
      default:
        return state;
    }
}
