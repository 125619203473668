import { fetchBillingInformation } from 'creators/billing';
// eslint-disable-next-line
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import style from './billing.module.scss';
// import { Header } from './components/header';
import { Payments } from './components/payments';
import { Plan } from './components/plan';

export const BillingPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchBillingInformation());
  }, [dispatch]);

  return (
    <div className={style.root}>
      {/* <Header /> */}
      <Plan />
      <Payments />
    </div>
  );
};
