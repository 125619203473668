export enum Api {
  LogIn = '/api/account/log_in',
  SetAvatar = '/api/account/set_avatar',
  GetAvatar = '/api/account/get_avatar',
  SignUp = '/api/account/sign_up',
  LogOut = '/api/account/log_out',
  Help = '/api/account/reset_pass',
  GetUsers = '/api/account/get_users',
  CheckSubscription = '/api/account/check_subscription',
  ResetPass = '/api/account/reset_pass',
  ReceivePass = '/api/account/set_pass',
  UploadTrack = '/api/track/upload',
  GetTracks = '/api/track/get',
  GetAllTracks = '/api/track/get_all',
  SetTrackInfo = '/api/track/set_info',
  DeleteTrack = '/api/track/delete',
  GetAppliedTracks = '/api/track/get_applied',
  GetPlaylistTracks = '/api/track/get_playlist',
  CreateJob = '/api/job/create',
  GetJobs = '/api/job/get',
  GetCompanyJobs = '/api/job/get_company',
  GetAllJobs = '/api/job/get_all',
  CreateApplication = '/api/application/create',
  CreatePlaylist = '/api/playlist/create',
  GetPlaylist = '/api/playlist/get',
  DeletePlaylist = '/api/playlist/delete',
  UpdatePlaylist = '/api/playlist/update',
  ConfirmEmails = 'api/confirm/email',
  GetStats = '/api/account/get_stat',
  SetRating = '/api/rating/set_track',
  SharePlaylist = '/api/playlist/update_share_status',
  UpdateStatus = '/api/job/update_status',
  UploadJobAvatar = '/api/job/set_avatar',
  GetJobAvatar = '/api/job/get_avatar',
  SetAvatarId = '/api/job/set_avatar_id',
  UpdateJob = '/api/job/update',
  ChangeJobOwner = '/api/job/change_owner',
  GetUsersForAdminPage = '/api/admin/get_all_users',
  DeleteUserFromAdminPage = '/api/admin/delete_user',
  ListenEvent = '/api/event/track_listened',
  UploadEvent = '/api/event/track_uploaded',
  BlockUser = '/api/admin/disable_user',
  UnBlockUser = '/api/admin/turn_on_user',
  GetTrackURL = '/api/track/get_download_url',
  UpdateSubscription = '/api/subscription/update',
  CreateCompany = '/api/company/create',
  GetCompanies = '/api/company/get',
  CreateInviteInCompany = '/api/company/create_invite',
  DeleteCompanyById = '/api/company/delete',
  GetReceivedInvite = '/api/company/get_received_invites',
  GetCreatedInvite = '/api/company/get_created_invites',
  UpdateInviteStatus = '/api/company/update_invite_status',
  DeleteInviteById = '/api/company/delete_invite',
  GetSharedPlaylist = '/api/playlist/get_shared',
  GetAllCompanies = '/api/company/get_all',
  UpdateShareType = '/api/job/update_share_type',
  ChangeOwnerOfJob = '/api/job/change_owner',
  RegisterFromInvite = '/api/account/company_invite_sign_up',
  SharePlaylistToMember = '/api/playlist/share_to_member',
  SharePlaylistTracks = '/api/track/get_shareable_playlist',
  ShareablePlaylistInfo = '/api/playlist/get_shareable_playlist_info',
  ShareablePlaylistLink = '/api/playlist/get_shareable_links',
  CreateShareableLink = '/api/playlist/create_shareable_link',
  DownloadAllShareablePlaylistTracks = '/api/playlist/download_shareable_zip',
  DeleteMemberFromCompany = '/api/company/delete_member',
  ConfirmUserEmail = '/api/admin/confirm_user_email',
  GetRegisteredEmail = '/api/account/get_register_email',
  SubscriptionList = '/api/product/get_all',
  CreateCheckoutSession = '/api/stripe/create_checkout_session',
  CheckSuccessPayment = '/api/stripe/success_payment_check',
  CreatePortalSession = '/api/stripe/create_portal_session',
  Unsubscribe = '/api/stripe/cancel_subscription',
  UpdateApprove = '/api/admin/update_approve',
  CurrentSubscription = '/api/stripe/get_subscription',
  CreateAffiliate = '/api/affiliate/create',
  GetAffiliate = '/api/affiliate/get',
  UpdateUserRating = '/api/admin/update_user_rating',
  UpdateHiddenSubscription = '/api/admin/update_hidden_subscription',
  GetJobById = '/api/job/get_job',
  GetTeamList = '/api/team/get',
  DeleteTeam = '/api/team/delete',
  CreateTeam = '/api/team/create',
  ManagerInvites = '/api/team/get_manager_invites',
  MusicianInvites = '/api/team/get_musician_invites',
  InviteFromTrack = '/api/team/invite_by_track',
  UpdateTeamInviteStatus = '/api/team/update_invite_status',
  GetMusicianTeams = '/api/team/get_musician',
  SearchTracks = '/api/track/get_like',
  GetPlaylistsByJobId = '/api/application/get_by_job',
  DeletePlaylistApp = '/api/application/delete',
  UpdatePlaylistApp = '/api/application/update',
  DeleteTrackPlaylistApp = '/api/application/delete_tracks',
  GetShareablesLinksApp = '/api/application/get_shareable_links',
  CreateShareableLinkApp = '/api/application/create_shareable_link',
  ShareableMusicianPlaylist = '/api/application/get_shareable_application_info',
  SharePlaylistMusicianTracks = '/api/track/get_shareable_application',
  DownloadAllShareableMusicianTracks = '/api/application/download_shareable_zip',
  CheckEmail = '/api/account/check_email',
  SearchUsers = '/api/admin/get_users',
  SubscriptionLimit = '/api/job/get_subscription_limit',
  PlaylistUploadEvent = '/api/event/playlist_uploaded',
  GetPlaylistMetadata = '/api/playlist/download_metadata_in_csv',
  GetDefaultPLaylist = '/api/application/get_default',
  HoldUser = '/api/admin/set_on_hold_user',
  Playlists = '/api/application/get',
  GetTracksToPlaylist = '/api/application/get_tracks',
  UpdateStripeSubscription = '/api/stripe/update_subscription',
  TrackHighLight = '/api/playlist/set_track_listened',
  AppHighLight = '/api/application/set_track_listened',
  GetBillingInfo = '/api/subscription/get_billing_info',
  ChangeDefaultMethod = '/api/subscription/change_default_payment_method',
  AddPayment = '/api/subscription/add_payment_method',
  DeletePayment = '/api/subscription/delete_payment_method',
  JobsGetLike = '/api/job/get_like',
  AccountChangeName = '/api/account/change_name',
  AccountConfirmChangeEmail = '/api/account/confirm_changing_email',
  AccountChangeEmail = '/api/account/change_email',
  AccountChangePassword = '/api/account/change_password',
  AccountDeleteAvatar = '/api/account/delete_avatar',
  AccountConfirmNewEmail = '/api/account/confirm_email',
  GetInfo = '/api/account/get_info',
  GetStatistics = '/api/statistic/get',
  GetAllUsersCSV = '/api/admin/download_information_about_users_in_csv',
  DownloadAllTracks = '/api/admin/download_all_tracks_info_in_csv',
  GetCompanyInfo = '/api/company/get_info',
  VerifyCompany = '/api/company/set_verified',
  UpdateCompanyAvatar = '/api/company/upload_logo',
  UpdateCompanyBg = '/api/company/upload_background',
  GetAffiliateBg = '/api/company/get_background_by_affiliate_code',
  UpdateCompanySearchIcon = '/api/company/upload_logo_for_job_type',
  GetJobTypes = '/api/job/get_types',
  GetChartDates = '/api/statistic/get_available_dates',
  UpdateCompanyLoginImage = '/api/company/upload_login_background',
  GetSingUpBgByCompanyId = '/api/company/get_background',
  UploadPlaylistBackground = '/api/application/upload_background',
  GetPlaylistBackground = '/api/application/get_background',
  SubscribePromotion = '/api/user/subscribe_promotion',
  GetSubscribedPromotion = '/api/user/get_subscribed_promotion',
}
