export const publicRoute = {
  admin: '/admin',
  signUp: '/sign-up',
  registerFromCompanyInvite: '/register-from-company-invite',
  main: '/',
  confirm: '/confirm',
  confirmChangeEmail: '/confirm-change-email',
  confirmNewEmail: '/confirm-new-email',
  resetPass: '/reset-pass',
  help: '/help',
  shareablePlaylist: '/shareable-playlist',
  shareableApplication: '/shareable-application',
  privacyPolicy: '/privacy-policy',
  technicalWork: '/technical-works',
  notFound: '*',
};

export const route = {
  statistic: '/statistic',
  trackList: '/track/list',
  jobList: '/job/list',
  playlist: '/playlist',
  activeJobs: '/jobs/active',
  companyList: '/company/list',
  admin: '/admin',
  statistics: '/statistics',
  inviteList: '/invite/list',
  companyInfo: '/company-info',
  microPromotions: '/micro-promotions'
};

export const privateRoute = {
  main: '/',
  creator: route,
  creatorPrefix: '/creator',
  admin: route,
  adminPrefix: '/sh_admin',
  manager: route,
  managerPrefix: '/manager',
  pricing: '/pricing',
  affiliate: '/affiliate',
  team: '/team',
  billing: '/billing',
  profile: '/profile',
  confirmNewEmail: '/confirm-new-email',
  successCheckout: '/success-checkout',
  cancelChekout: '/cancel-checkout',
  announceNetwrk:'/announce-netwrk',
  masteringChannel:'/mastering-channel',
};
