import * as AuthActions from 'actions/auth';
import { WSApi } from 'enums/wsApi';
// eslint-disable-next-line
import React, { memo, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import useWebSocket from 'react-use-websocket';
import { sid$ } from 'selectors/auth';
import { envWSDetector } from 'utils/env';

interface IProps {
  children: JSX.Element;
}

export const WebsocketHandler = memo(({ children }: IProps) => {
  const [socketUrl] = useState(`wss://${envWSDetector()}smashhaus.com/ws`);
  const sid = useSelector(sid$);
  const dispatch = useDispatch();
  const memoSocketUrl = useMemo(() => socketUrl, [socketUrl]);
  const { lastMessage, sendMessage } = useWebSocket(memoSocketUrl, { share: true });
  const memoLastMessage = useMemo(() => lastMessage, [lastMessage]);
  const history = useHistory();

  useEffect(() => {
    if (memoLastMessage) {
      const answer = JSON.parse(memoLastMessage?.data);
      if (answer?.action === WSApi.Logout) {
        dispatch(AuthActions.initAllApplication());
        history.push('/');
      }
    }

    // eslint-disable-next-line
  }, [memoLastMessage]);

  useEffect(() => {
    if (sid !== null) {
      sendMessage(JSON.stringify({ action: 'initialization', sid }));
    }
    // eslint-disable-next-line
  }, [sid]);

  return <>{children}</>;
});
