import React from 'react';

import styles from './updatePolicyTermPopup.module.scss';

interface UpdatePolicyTermPopupProps {
  onAgree: () => void;
  reviewPrivacyPolicy: () => void;
}

const UpdatePolicyTermPopup: React.FC<UpdatePolicyTermPopupProps> = ({ onAgree, reviewPrivacyPolicy }) => {
  return (
    <div className={styles.promotion_popup}>
        <div className={styles.background}></div>
        <div className={styles.mainContent}>
            <div className={styles.second_section}>
                <div className={styles.headingContent}>
                    <h1 className={styles.main_heading}>We've Updated Our <b>Privacy Policy</b></h1>
                </div>
                <div className={styles.textContent}>
                    <p className={styles.subText}>We've made important updates to our Terms of Service and Privacy Policy to enhance user value through third-party
                        partnerships.</p>
                    <p className={styles.mainText}>Your privacy remains our top priority.</p>
                    <p className={styles.mainText}>We want to be very clear:</p>
                    <ul>
                        <li>We believe in supporting artists and humans—not bots.</li>
                        <li>We will NEVER sell or share your data with A.I. agents seeking to replicate your content.</li>
                        <li>We will never sell or share your data with external sources.</li>
                    </ul>
                    <p>Please review our updated Privacy Policy and agree to the terms to continue using our platform.</p>
                </div>
                <div className={styles.btnsWrapper}>
                    <button onClick={reviewPrivacyPolicy} className={`${styles.button} ${styles.privacyPolicy}`}>Review Privacy Policy</button>
                    <button onClick={onAgree} className={`${styles.button} ${styles.agreeToUpdate}`}>Agree</button>
                </div>
            </div>
        </div>
    </div>
  );
};

export default UpdatePolicyTermPopup;
