import Tooltip from '@material-ui/core/Tooltip';
import premiumFree from 'assets/img/diamond.svg';
import starterFree from 'assets/img/pie-chart.svg';
import premium from 'assets/img/premium.svg';
import starter from 'assets/img/pro.svg';
import unlimited from 'assets/img/verified.svg';
import { SubscriptionName } from 'interfaces/state/SubscriptionList';
// eslint-disable-next-line
import React, { memo } from 'react';

import styles from './style.module.scss';

interface IProps {
  subscription: SubscriptionName;
}

const SubscriptionIconComponent = ({ subscription }: IProps) => {
  const icon = {
    free: '',
    unlimited,
    starter,
    premium,
    pro: premium,
    verified: unlimited,
    'starter-free': starterFree,
    'premium-free': premiumFree,
    'premium-basic': premium,
    'vip / managed': unlimited,
  };

  const unnececarySubscriptionTypes = ['free'];
  if (unnececarySubscriptionTypes.includes(subscription.toLowerCase())) return null;
  return (
    <div className={styles.root}>
      <Tooltip title={`Your Subscription type is ${subscription.toUpperCase()}`}>
        <img src={icon[subscription.toLowerCase()]} alt="subscription" className={styles.icon} loading="lazy" />
      </Tooltip>
    </div>
  );
};

export const SubscriptionIcon = memo(SubscriptionIconComponent);
