import GetAppIcon from '@material-ui/icons/GetApp';
import axios from 'axios';
import { errorNotification } from 'components/errorNotification/errorNotification';
import { successNotification } from 'components/successNotification/successNotification';
import { fetchAllUsersInCSV } from 'creators/admin';
import { WSApi } from 'enums/wsApi';
import moment from 'moment';
// eslint-disable-next-line
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useWebSocket from 'react-use-websocket';
import { sid$ } from 'selectors/auth';
import { closedJobs$, openJobs$, songsSubmittedAllTime$, uploadedSongs$, users$ } from 'selectors/statistics';
import { DATE_TIME_FORMAT, uploadLinkHandler } from 'utils';
import { envWSDetector } from 'utils/env';
import { firstCapitalLetter } from 'utils/firstCapitalLetter';

import { InformationItem } from '../information';
import style from './infoList.module.scss';

export const InfoList = () => {
  const [isLoading, setLoading] = useState(false);
  const [loadType, setLoadType] = useState<null | 'tracks' | 'users'>(null);
  const users = useSelector(users$);
  const sid = useSelector(sid$);
  const uploadedSongs = useSelector(uploadedSongs$);
  const openJobs = useSelector(openJobs$);
  const closedJobs = useSelector(closedJobs$);
  const songsSubmittedAllTime = useSelector(songsSubmittedAllTime$);
  const dispatch = useDispatch();
  const { sendMessage, lastMessage } = useWebSocket(`wss://${envWSDetector()}smashhaus.com/ws`, { share: true });

  useEffect(() => {
    if (lastMessage) {
      const answer = JSON.parse(lastMessage?.data);
      setLoading(false);
      if (answer?.action === WSApi.DownloadTracks) {
        setLoading(false);
        const date = moment().format(DATE_TIME_FORMAT);
        const text = `Tracks ${date}.csv`;
        if (answer?.error) {
          errorNotification(firstCapitalLetter(answer.error.replace(/_|-|}|{/g, ' ')));
        } else {
          axios({
            url: answer?.response,
            method: 'GET',
            responseType: 'blob',
            headers: {
              'Content-Disposition': `attachment; filename="${encodeURI(text)}"`,
            },
            onDownloadProgress: p => {
              const total = Number(((p.loaded / p.total) * 100).toFixed(1));
              console.log(total);

              if (p.loaded / p.total === 1) {
                successNotification(`Downloaded file`, 8000);
              }
            },
          }).then(r => {
            uploadLinkHandler(text, r.data);
          });
        }
      }
    }
    // eslint-disable-next-line
  }, [lastMessage]);

  const handleLoading = (value: boolean, lt: null | 'tracks' | 'users') => {
    setLoadType(lt);
    setLoading(value);
  };
  const handleDownloadAllUsers = () => {
    dispatch(fetchAllUsersInCSV(handleLoading));
  };

  const handleDownloadAllTracks = () => {
    setLoading(true);
    setLoadType('tracks');
    sendMessage(JSON.stringify({ action: WSApi.DownloadTracks, sid }));
  };

  return (
    <div className={style.root}>
      <div className={style.content}>
        <InformationItem name={'Users'} value={users} />
        <button className={style.btn} onClick={handleDownloadAllUsers} disabled={isLoading && loadType === 'users'}>
          <span className={style.text}>csv</span>
          <GetAppIcon className={style.icon} />
        </button>
      </div>
      <InformationItem name={'Open Jobs'} value={openJobs} />
      <InformationItem name={'Closed Jobs'} value={closedJobs} />

      <div className={style.content}>
        <InformationItem name={'Uploaded songs'} value={uploadedSongs} />
        <button className={style.btn} onClick={handleDownloadAllTracks} disabled={isLoading && loadType === 'tracks'}>
          <span className={style.text}>csv</span>
          <GetAppIcon className={style.icon} />
        </button>
      </div>
      <InformationItem name={'Songs submitted for all time'} value={songsSubmittedAllTime} />
    </div>
  );
};
