import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

interface CustomSvgIconProps {
  path: string;        // path should be a string
  width?: number;      // width is an optional number (default 25)
  height?: number;     // height is an optional number (default 24)
  [key: string]: any;  // this allows other props like className, style, etc.
}

export const CustomSvgIcon: React.FC<CustomSvgIconProps> = ({ path, width = 25, height = 24, pathFill="#FFD700", viewBox="0 0 25 24", svgFill="none", ...props }) => (
  <SvgIcon {...props}>
    <svg width={width} height={height} viewBox={viewBox} fill={svgFill} xmlns="http://www.w3.org/2000/svg">
      <path d={path} fill={pathFill} />
    </svg>
  </SvgIcon>
);