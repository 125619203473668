import 'react-virtualized/styles.css';

import { Loader } from 'components/loader/loader';
import { IUser } from 'interfaces/IUser';
import UpdateApproveState from 'interfaces/state/UpdateApproveState';
// eslint-disable-next-line
import React, { useCallback } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { UserTableHead } from './head';
import { UserTableItem } from './tableItem';
import style from './userTable.module.scss';

interface IProps {
  items: string[];
  itemsByEmail: { [key: string]: IUser | null } | null;
  title?: string;
  adminType: 'creator' | 'manager' | 'admin';
  type: string;
  approve?: UpdateApproveState['approve'];
  isLoading: boolean;
  fetchUsersForAdmin: (
    limit: number,
    offset: number,
    user_type: 'creator' | 'admin' | 'manager',
    sort_by?: 'date' | 'email',
    sort_direction?: 'up' | 'down',
    on_hold?: boolean
  ) => void;
  search: string;
  sortBy?: 'date' | 'email';
  isOnHold: boolean;
  direction: 'up' | 'down';
}

const UserTableComponent = ({
  isLoading,
  type,
  items,
  title,
  itemsByEmail,
  adminType,
  approve,
  search,
  sortBy,
  isOnHold,
  direction,
  fetchUsersForAdmin,
}: IProps) => {
  const fetchNextUsers = useCallback(() => {
    fetchUsersForAdmin(20, items.length, adminType, sortBy, direction, isOnHold);
    // eslint-disable-next-line
  }, [items]);
  return (
    <>
      {isLoading && (
        <div className={style.loaderContainer}>
          <Loader />
        </div>
      )}
      <UserTableHead title={title} userType={adminType} />
      <section className={style.root} id="scrollableDiv">
        <InfiniteScroll
          dataLength={items.length}
          className={style.infinityWrapper}
          next={fetchNextUsers}
          hasMore={!search}
          loader={<div />}
          scrollThreshold={'200px'}
          endMessage={
            <>
              {!items.length && (
                <p style={{ textAlign: 'center', marginTop: 20 }}>
                  <b>Nothing was found</b>
                </p>
              )}
              {!!items.length && (
                <p style={{ textAlign: 'center', marginTop: 20 }}>
                  <b>---</b>
                </p>
              )}
            </>
          }
          scrollableTarget="scrollableDiv"
        >
          {[...new Set(items)].map(item => (
            <UserTableItem
              key={item}
              type={type}
              adminType={adminType}
              item={itemsByEmail![item]!}
              approve={approve}
              isOnHold={isOnHold}
            />
          ))}
        </InfiniteScroll>
      </section>
    </>
  );
};

export const UserTable = UserTableComponent;
