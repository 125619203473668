export const svgPathAttr = {
    hornFill: {
        svgFill: "none",
        d: "M19.1842 2.32799C20.0302 1.86299 21.0352 2.48899 21.0352 3.42599V20.574C21.0352 21.511 20.0302 22.137 19.1842 21.672C18.0472 21.048 14.6812 19.23 12.6662 18.429C11.4165 17.9259 10.1168 17.5572 8.78916 17.329L8.01616 21.196C7.99042 21.3248 7.93956 21.4473 7.86648 21.5565C7.7934 21.6657 7.69953 21.7594 7.59024 21.8323C7.48094 21.9051 7.35836 21.9558 7.22949 21.9813C7.10062 22.0068 6.96798 22.0067 6.83916 21.981C6.71033 21.9553 6.58783 21.9044 6.47866 21.8313C6.36949 21.7582 6.27579 21.6644 6.20289 21.5551C6.13 21.4458 6.07934 21.3232 6.05382 21.1943C6.0283 21.0655 6.02842 20.9328 6.05416 20.804L6.79716 17.089C6.52774 17.0688 6.25805 17.0524 5.98816 17.04C4.38516 16.966 3.03516 15.671 3.03516 14V9.99999C3.03516 8.32899 4.38516 7.03399 5.98816 6.95999C6.34282 6.94332 6.69182 6.92066 7.03516 6.89199V16H7.06916C7.15693 15.848 7.28316 15.7218 7.43518 15.634C7.58719 15.5462 7.75963 15.5 7.93516 15.5C8.11068 15.5 8.28312 15.5462 8.43513 15.634C8.58715 15.7218 8.71338 15.848 8.80116 16H9.03516V6.62799C10.2773 6.39722 11.4933 6.04313 12.6652 5.57099C14.6812 4.76999 18.0472 2.95099 19.1842 2.32799Z",
        pathFill: "#464647",
        viewBox: "0 0 24 24",
        width: 25,
        height: 24,
    },
    arrowRight: {
        svgFill: "none",
        d: "M20.7071 8.70711C21.0976 8.31658 21.0976 7.68342 20.7071 7.29289L14.3431 0.928932C13.9526 0.538408 13.3195 0.538408 12.9289 0.928932C12.5384 1.31946 12.5384 1.95262 12.9289 2.34315L18.5858 8L12.9289 13.6569C12.5384 14.0474 12.5384 14.6805 12.9289 15.0711C13.3195 15.4616 13.9526 15.4616 14.3431 15.0711L20.7071 8.70711ZM0.829102 9H20V7H0.829102V9Z",
        pathFill: "#ffff",
        viewBox: "0 0 21 16",
        width: 21,
        height: 16,
    },
    netwrk: {
        svgFill: "none",
        d: "M131.917 8.78988C154.423 -11.0052 190 4.80357 190 34.5991V122.257C190 150.236 158.134 166.6 135.013 150.493L38.6182 83.344C34.3365 80.3613 28.4354 83.3916 28.4354 88.5729V204.4C28.4354 210.038 35.2738 212.917 39.3793 209.007L90.3111 160.505C95.9687 155.117 104.969 155.288 110.414 160.886C115.859 166.484 115.687 175.39 110.029 180.778L59.0974 229.28C36.9273 250.393 0 234.846 0 204.4V88.5729C0 60.5939 31.8662 44.2304 54.9872 60.3367L151.382 127.486C155.663 130.469 161.565 127.438 161.565 122.257V34.5991C161.565 29.0814 154.976 26.1539 150.809 29.8196L109.616 66.051C103.747 71.2129 94.7604 70.6898 89.5436 64.8826C84.3269 59.0754 84.8555 50.1832 90.7244 45.0212L131.917 8.78988Z",
        pathFill: "#464647",
        viewBox: "0 0 190 239",
        width: 25,
        height: 24,
    }
};
  